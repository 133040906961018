import type { MerchantKioskDetailModel } from "./kiosk";
import type { UploadModel } from "./index";

export interface LeapAppMerchantModel {
	Id: string;
	Name: string;
	Email: string;
	EmailVerified: boolean;
	Points: number;
	MerchantType: MerchantType;
	Payments: PaymentServiceModel[];
	Outlets: LeapAppOutletModel[];
}

export enum MerchantType {
	Normal = 0,
	PaymentApplicant = 1,
	Appropriator = 2,
	ClawOutletOwner = 4,
}

interface PaymentServiceModel {
	Name: string;
	CodeName: string;
	Status: FeatureStatus;
}

export enum FeatureStatus {
	NotApplied = 0,
	Applying = 1,
	Activated = 2,
	Deactivated = -1,
}

export interface CityOption {
	Id: string;
	Name: string;
	Towns: TownOption[];
}

export interface TownOption {
	Id: string;
	Name: string;
	ZipCode: string;
}

export interface OutletKioskModel {
	Id: string;
	MerchantName: string;
	Name: string;
	FullAddress?: string;
	Kiosks: MerchantKioskDetailModel[];
}

export interface OutletShareModel {
	Id: string;
	Name: string;
	Link: string;
}

export interface OutletModelBase {
	Id?: string;
	IsDisabled: boolean;
	IsHidden: boolean;
	Name: string;
	ShortName: string;
}

export interface OutletModel extends OutletModelBase {
	MerchantId: string;
	MerchantName: string;
	ContractDate?: Date;
	SellDate?: Date;
	OpenDate?: Date;
	Remark: string;
	Contact: ContactModel;
}

export interface LeapAppOutletModel {
	Id: string;
	Name: string;
	TerminalCount: number;
	Contact: ContactModel;
}

export interface OutletEditModel extends ILogoHero {
	Id?: string;
	MerchantId: string;
	Name: string;
	Description: string;
	ShortName: string;
	ContractDate?: Date;
	UseGPS: boolean;
	SellDate?: Date;
	OpenDate?: Date;
	Remark: string;
	Contact: ContactModel;
	Advertisement: UploadModel[];
}

export interface ILogoHero {
	Hero: UploadModel[];
	Logo: UploadModel[];
}

export interface MerchantApplyModel {
	Lock: string;
	Id: string;
	PersonType: PersonType;
	MerchantType: MerchantType;
	UniformId: string;
	Email: string;
	Terms: boolean;
	IsVerified?: boolean;
	BrandName: string;
	RegistrationContact: ContactModel;
	LiaisonContact: ContactModel;
	AdministratorContact: ContactModel;
}

export interface ContactModel extends Coordinates {
	Name: string;
	Address?: string;
	TownId?: string;
	Phone?: string;
	Email?: string;
	readonly FullAddress?: string;
	readonly CityAddress?: string;
	Website?: string;
	Facebook?: string;
	Instagram?: string;
	TikTok?: string;
	GoogleMap?: string;
}

export interface Coordinates {
	Id: string;
	Longitude?: number;
	Latitude?: number;
}

export interface MerchantEditModel {
	Lock: string;
	SerialString: string;
	Id: string;
	IdCardPhoto: UploadModel[];
	Logo: UploadModel[];
	MerchantRegistration: UploadModel[];
	MerchantType: number;
	Town: string;
	Address: string;
	Phone: string;
	Fax: string;
	EstablishmentDate: Date;
	PaidInCapital: number | null;
	AdministratorName: string;
	AdministratorPhone: string;
	AdministratorEmail: string;
	AdministratorBirthday: Date;
	AdministratorIdCardNumber: string;
	Remark: string;
	PersonType: PersonType;
	UniformId: string;
	Name: string;
	RegistrationName: string;
	Category: string;
	LiaisonName: string;
	LiaisonPhone: string;
	LiaisonEmail: string;
}

export enum PersonType {
	Legal = 0,
	Natural = 1,
}
