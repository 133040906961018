var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
let cityTownData;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.city = null;
        this.skip = false;
    }
    created() {
        this.load();
    }
    onCityChange() {
        if (!this.skip)
            this.$emit('input', null);
        this.skip = false;
    }
    toString(townId = this.value) {
        if (!townId)
            return undefined;
        const city = this.data.find(c => c.Id == this.city);
        const town = city.Towns.find(t => t.Id == townId);
        return city.Name + town.Name;
    }
    get towns() {
        var _a, _b;
        if (!this.data || !this.city)
            return [];
        return (_b = (_a = this.data.find(c => c.Id == this.city)) === null || _a === void 0 ? void 0 : _a.Towns) !== null && _b !== void 0 ? _b : [];
    }
    load() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (cityTownData) {
                this.data = cityTownData;
            }
            else {
                try {
                    this.data = cityTownData = yield post("/api/Merchant/Town");
                }
                catch (e) {
                    if (e instanceof Error)
                        yield this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
                    this.$router.back();
                    return;
                }
            }
            if (this.value) {
                this.skip = true;
                this.city = (_b = (_a = this.data.find(c => c.Towns.some(t => t.Id == this.value))) === null || _a === void 0 ? void 0 : _a.Id) !== null && _b !== void 0 ? _b : null;
            }
        });
    }
    get required() {
        if (typeof this.rules == "string")
            return this.rules.includes('required');
        if (typeof this.rules == "object")
            return 'required' in this.rules;
        return false;
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "value", void 0);
__decorate([
    Prop()
], default_1.prototype, "rules", void 0);
__decorate([
    Watch("city")
], default_1.prototype, "onCityChange", null);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
