
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import CityTown from "@/components/form/CityTown.vue";
	import LogoHeroEdit from "../components/LogoHeroEdit.vue";

	import type { OutletEditModel, MerchantApplyModel, ContactModel, Coordinates } from "@common/models/merchant";

	export const option = {
		showAlert: true,
	};

	@Component({ components: { CityTown, LogoHeroEdit } })
	export default class extends Vue {
		$refs!: {
			town: CityTown;
		}

		@Prop() public id?: string;

		@Inject() public readonly alert!: AlertMethod;

		protected merchant: MerchantApplyModel | null = null;
		protected data: OutletEditModel | null = null;

		mounted(): void {
			if(option.showAlert && !this.id) this.alert('新增的門市會套用現有以及未來申請的支付', '新增門市', 'warning');
			option.showAlert = true;
			this.load();
		}

		protected get title(): string {
			return this.id ? "編輯門市" : "新增門市";
		}

		protected copy(): void {
			this.data!.Name = this.merchant!.BrandName;
			this.data!.Contact.Phone = this.merchant!.LiaisonContact.Phone!;
		}

		private async load(): Promise<void> {
			const tasks: Promise<unknown>[] = [
				post<MerchantApplyModel>("/api/Merchant/Edit").then(v => this.merchant = v),
			];
			if(this.id) {
				tasks.push(
					post<OutletEditModel>("/api/Merchant/GetOutlet", { Id: this.id }).then(v => this.data = v)
				);
			} else {
				// Vue2 當中沒有利用 Proxy 來捕捉 setter，
				// 所以一開始必須要先填入 undefined，對應的欄位才會是反應式的
				this.data = {
					MerchantId: '',
					Name: '',
					ShortName: '',
					Description: '',
					Contact: {
						Phone: '',
						Address: '',
						TownId: '',
						Facebook: undefined,
						Instagram: undefined,
						TikTok: undefined,
						GoogleMap: undefined,
						Longitude: undefined,
						Latitude: undefined,
					} as ContactModel,
					UseGPS: true,
					Hero: [],
					Logo: [],
					Advertisement: [],
					ContractDate: undefined,
					SellDate: undefined,
					OpenDate: undefined,
					Remark: '',
				};
			}
			await Promise.all(tasks);
			if(!this.id) {
				this.data!.MerchantId = this.merchant!.Id;
			} else if(!this.data!.Contact.Longitude) {
				// 如果過去沒有填過經緯度，立刻試著填入
				this.addressChange();
			}
		}

		protected async send(): Promise<void> {
			await post<boolean>('/api/Merchant/EditOutlet', { ...this.data });
			this.$router.back();
		}

		protected async addressChange(): Promise<void> {
			const coor = await this.getCoordiantes();
			if(coor) {
				const contact = this.data!.Contact;
				contact.Longitude = coor.Longitude;
				contact.Latitude = coor.Latitude;
			}
		}

		protected async getGoogleLink(): Promise<void> {
			const coor = await this.getCoordiantes();
			if(coor) {
				const url = await post<string>('/api/Merchant/GetShortURL', { Id: coor.Id });
				const contact = this.data!.Contact;
				contact.GoogleMap = url;
			}
		}

		protected async getCoordiantes(): Promise<Coordinates | undefined> {
			const contact = this.data?.Contact;
			if(!contact || !contact.Address) return;
			const cityTown = this.$refs.town.toString(contact.TownId);
			if(!cityTown) return;
			const address = cityTown + contact.Address;
			try {
				return await post<Coordinates>('/api/Merchant/GetCoordinates', { Id: address });
			} catch(e) {
				return undefined;
			}
		}

		protected GoogleInfo(): void {
			this.alert('<div class="text-left"><ol class="pl-3"><li>開啟 Google 地圖 App，查找出門市。</li><li>點選「分享」，然後複製連結資訊。</li></ol></div>', "取得 Google<br>地圖連結");
		}
	}
